<template>
    <div>
        <h1>Sales Revenue</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Chart v-bind:results="state.results[state.report]"></Chart>
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./sales_revenue_opt";
    import Results from "./utils/Results";
    import Chart from "@/components/utils/Chart"

    export default {
        name: "sales_revenue",
        components: {
            Results,
            Options,
            Chart,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate : {val: new Date(new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setMonth(new Date().getMonth() + 1)).setDate(1)).toLocaleDateString(), qsField: '[dates][actual_ship_from]'},
                    endDate : {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][cdi_location_id]'},
                    interval: {val: 'M', qsField: '[interval]'},
                    companyId: {val: '', qsField: '[numbers][d.company_id]'},
                    state: {val: 0, qsField: '[strings][sa.state]'},
                    distributorId: {val: '', qsField: '[numbers][distributor_id]'},
                    invoiceStatus: {val: '-1', qsField: '[invoice_sent]'},
                    salesPersonId: {val: '', qsField: '[subquery][tracker_sales_user]', defaultVal: ''},
                    chart: {val: '1', qsField: '[chart]'},
                    distributorCity: {val: '', qsField: '[strings][sa.city]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        }, created() {
            this.$appStore.setTitle(['Sales Revenue']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields,this.dataFields);
        },
    }
</script>

<style scoped>

</style>