<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date From</label>
                <DatePicker v-model="state.optionFields.startDate.val" :input-attributes="{class: 'form-control'}" ></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date To</label>
                <DatePicker v-model="state.optionFields.endDate.val" :input-attributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Interval</label>
                <select v-model="state.optionFields.interval.val" class='form-control'>
                    <option value="D">Day</option>
                    <option value="W">Week</option>
                    <option value="M" selected>Month</option>
                    <option value="Y">Year</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>State</label>
                <select class="form-control" v-model="state.optionFields.state.val">
                    <option value="0">All</option>
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Customer</label>
                <Typeahead cdi_type='search_my_users' @objectSelected="setCustomer" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Customer City</label>
                <Typeahead cdi_type='city' v-bind:ID.sync='state.optionFields.distributorCity.val' />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Invoice</label>
                <select v-model="state.optionFields.invoiceStatus.val" class='form-control'>
                    <option value='-1'>Sent and Unsent</option>
                    <option value='1'>Sent</option>
                    <option value='0'>Unsent</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group' v-show="!toggleHide" >
                <label>Sales Person</label>
                <Typeahead ref="salesPerson" cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.salesPersonId.val" />
            </div>

            <div class="col-xs-12"><button type="button" @click="toggleMoreFilters()" class="btn btn-default btn-sm">{{ toggleHide ? 'More' : 'Less' }} Filters</button></div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import stateCache from '@/cache/state.cache.js';
    import DatePicker from '../utils/DatePicker';
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Options",
        components: {DatePicker, Typeahead },
        props: ['data'],
        data() {
            return {
                toggleHide: true,
                state: store.state,
                cache: {
                    cdiLocationCache,
                    stateCache
                }
            }
        },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
                if(this.toggleHide){
                    this.$refs.salesPerson.object = '';
                }
            },
            setCustomer: function(userData) {
                this.state.optionFields.distributorId.val = userData.id;
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>